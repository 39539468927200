export default {
  'user-register-result.login.userName': 'userName',
  'user-register-result.login.password': 'password',
  'user-register-result.login.message-invalid-credentials':
    'Invalid username or password（admin/ant.design）',
  'user-register-result.login.message-invalid-verification-code': 'Invalid verification code',
  'user-register-result.login.tab-login-credentials': 'Credentials',
  'user-register-result.login.tab-login-mobile': 'Mobile number',
  'user-register-result.login.remember-me': 'Remember me',
  'user-register-result.login.forgot-password': 'Forgot your password?',
  'user-register-result.login.sign-in-with': 'Sign in with',
  'user-register-result.login.signup': 'Sign up',
  'user-register-result.login.login': 'Login',
  'user-register-result.register.register': 'Register',
  'user-register-result.register.get-verification-code': 'Get code',
  'user-register-result.register.sign-in': 'Already have an account?',
  'user-register-result.register-result.msg': 'Account：registered at {email}',
  'user-register-result.register-result.activation-email': 'Your account has been successfully created',
  'user-register-result.register-result.back-home': 'Back to home',
  'user-register-result.register-result.view-mailbox': 'View mailbox',
  'user-register-result.navBar.lang': 'Languages',
};
