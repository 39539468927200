import request from '@/utils/request';
import {serverUrl} from './utilityConnection'

export async function setMemberID(user: User) {
    localStorage.setItem("user", JSON.stringify(user));
}

export async function refreshUser(){
    let memberID = await getMemberID();
    if(memberID){
        let user = await request(serverUrl + '/getUserById?member_id=' + memberID);
        if(user.messageType == "OK"){
           await setMemberID(user.user);
        }
    }
}

export function getMemberID(): number {
    let memberID = -1;
    let user = localStorage.getItem("user");
    if (user) {
        let obj: User = JSON.parse(user);
        memberID = parseInt(obj.member_id.toString());
    }
    return memberID;
}

export function getMemberStatus():string {
    let status = "";
    let user = localStorage.getItem("user");
    if (user) {
        let obj: User = JSON.parse(user);
        status = obj.status;
    }
    return status;
}

export function getCurrentStep(status: string):number{
    if(status === "ACCT-SETUP-STEP2"){
        return 1
    }
    else if(status === "ACCT-SETUP-STEP3"){
        return 2
    }
    return 0
}

export function getMember(): User {
    let obj: User;
    let user = localStorage.getItem("user");
    if (user) {
        obj = JSON.parse(user);
    }
    return obj;
}

export function getMemberName(): string {
    let obj: User;
    var first_name:string = '';
    let user = localStorage.getItem("user");
    if (user) {
        obj = JSON.parse(user);
        first_name = obj.first_name;
    }
    return first_name;
}


export async function setMemberStatus(memberId: string, status: string){
    var data = new FormData();
    data.append("member_id", memberId);
    data.append("status", status);
    
    await fetch(serverUrl + "/updateMemberStatus", {
      method: "POST",
      body: data
    })
    .then(res => res.json())
    .then(parseRes => {
      return parseRes;
    })
    .catch(err => {
        console.info(err);
        return err;
    })
}

export function logout() {
    localStorage.removeItem("user");
}

export interface User {
    member_id: number;
    first_name: string;
    last_name: string;
    email: string;
    status: string;
}

export async function setListDefaultView(toggleCardList: boolean) {
    localStorage.setItem("toggleCardList", toggleCardList.toString());
}

export function getListDefaultView(): boolean {
    let toggleCardList = true;
    let temp = localStorage.getItem("toggleCardList");
    if (temp) {
        toggleCardList = (temp === "true" ? true : false);
    }
    return toggleCardList;
}

export async function processS3Images(tempList:string[]): string {
    var memberId = getMemberID();
    var data = new FormData();
    data.append("member_id", memberId.toString());
    data.append("thumbList", tempList.toString());
    
    await fetch(serverUrl + "/getUrlsForPdf", {
      method: "POST",
      body: data
    })
    .then(res => res.json())
    .then(parseRes => {
        //console.log("res:" + parseRes);
        return parseRes;
    })
    .catch(err => {
        console.info(err);
        return err;
    })
}