import React from 'react';
import { Spin, Icon } from 'antd';
import descImage from '../../assets/receiptowl-mobile-splash-big.png';
// loading components from code split
// https://umijs.org/plugin/umi-plugin-react.html#dynamicimport

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const PageLoading: React.FC = () => (
  <div style={{ paddingTop: 100, textAlign: 'center' }}>
    <img src={descImage} style={{width:"200px"}} /><br /><br /><br />
    <Spin size="large" />
  </div>
);
export default PageLoading;
