export default {
  'account-settings.menuMap.basic': 'Basic Settings',
  'account-settings.menuMap.security': 'Security Settings',
  'account-settings.menuMap.advancesecurity': 'Advanced Setting',
  'account-settings.advancesecurity.ocrOption': 'Automatically Read Receipt Total (BETA)',
  'account-settings.menuMap.binding': 'Account Binding',
  'account-settings.menuMap.notification': 'New Message Notification',
  
  'account-settings.basic.avatar': 'Avatar',
  'account-settings.basic.change-avatar': 'Change avatar',
  'account-settings.basic.email': 'Email',
  'account-settings.basic.email-message': 'Please input your email!',
  'account-settings.basic.firstName': 'First Name',
  'account-settings.basic.firstName-message': 'Please input your First Name!',
  'account-settings.basic.lastName': 'Last Name',
  'account-settings.basic.lastName-message': 'Please input your Last Name!',
  'account-settings.basic.profile': 'Personal profile',
  'account-settings.basic.profile-message': 'Please input your personal profile!',
  'account-settings.basic.profile-placeholder': 'Brief introduction to yourself',
  'account-settings.basic.country': 'Country/Region',
  'account-settings.basic.country-message': 'Please input your country!',
  'account-settings.basic.currency': 'Currency',
  'account-settings.basic.currency-message': 'Please input your currency!',
  'account-settings.basic.phone': 'Phone Number',
  'account-settings.basic.phone-message': 'Please input your phone!',
  'account-settings.basic.update': 'Update',
  'account-settings.basic.update.success': 'Update Successful',

  'account-settings.security.strong': 'Strong',
  'account-settings.security.medium': 'Medium',
  'account-settings.security.weak': 'Weak',
  'account-settings.security.password': 'Account Password',
  'account-settings.security.password-description': 'Current password strength：',
  'account-settings.security.phone': 'Security Phone',
  'account-settings.security.phone-description': 'Bound phone：',
  'account-settings.security.email': 'Email',
  'account-settings.security.email-description': 'Bound Email：',
  'account-settings.security.modify': 'Modify',
  'account-settings.security.set': 'Set',
  'account-settings.settings.open': 'Open',
  'account-settings.settings.close': 'Close',
};
