"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = findRoute;
exports.getUrlQuery = void 0;

var _reactRouterDom = require("react-router-dom");

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function findRoute(routes, path) {
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = routes[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var route = _step.value;

      if (route.routes) {
        var routesMatch = findRoute(route.routes, path);

        if (routesMatch) {
          return routesMatch;
        }
      } else if ((0, _reactRouterDom.matchPath)(path, route)) {
        // for get params (/news/1 => { params: { id： 1 } })
        var _matchPath = (0, _reactRouterDom.matchPath)(path, route),
            params = _matchPath.params;

        return _objectSpread({}, route, {
          params: params
        });
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return != null) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }
}

var getUrlQuery = function getUrlQuery(url) {
  if (typeof url === 'string' && url.indexOf('?') > -1) {
    var params = url.slice(1).split('&');

    if (Array.isArray(params) && params.length > 0) {
      return params.reduce(function (acc, curr) {
        var _curr$split = curr.split('='),
            _curr$split2 = _slicedToArray(_curr$split, 2),
            key = _curr$split2[0],
            value = _curr$split2[1];

        return _objectSpread({}, acc, _defineProperty({}, key, value));
      }, {});
    }
  }

  return {};
};

exports.getUrlQuery = getUrlQuery;